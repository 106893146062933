.Imgeffects {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: 480px){
    .Imgeffects { width: 100%;}
  }
  
  .Imgeffects .fadedbox {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    opacity: 0;
    width: 360px;
    height: 100px;
  }
  .Imgeffects:hover .fadedbox {
    opacity: 0.8;
  }
  .Imgeffects .text {
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    transform: translateY(30px);
    -webkit-transform: translateY(30px);
  }
  .Imgeffects .title {
    font-size: 2.5em;
    text-transform: uppercase;
    opacity: 0;
    transition-delay: 0.2s;
    transition-duration: 0.3s;
  }
  .Imgeffects:hover .title,
  .Imgeffects:focus .title {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
  .Footer {
    margin-bottom: 100px;
  }

  .Test {
    height: 150px;
    width: 150px;
    background-color: red;

  }

  .Test:hover {
    background-color: green;
  }